<template>
   <div class="card mb-1">
		<div class="card-body p-12">
         <!-- Cabecalho -->
         <div class="row">
            <div class="col mb-2 align-self-center">
               <div class="limitador-1 mb-1">
                  <i class="far fa-store color-theme font-13 me-1"></i><strong class="subtitle font-15 text-capitalize"> {{ loja.nomeLoja }}</strong>
                  <small class="mx-2">|</small><span class="font-13"><i class="far fa-tag font-11 me-1"></i> {{ loja.numeroLoja }}</span>
               </div>
            </div>
            <div class="w-max-content mb-2">
               <a href="javascript:;" class="badge badge-default-outline mb-1 cursor-auto d-none d-xl-block">
                  <i class="far fa-info-circle me-1"></i> Vendas contabilizadas na loja de {{ dataInicio.getDate().toString().padStart(2, "0") +'/'+ (dataInicio.getMonth() + 1).toString().padStart(2, "0") +'/'+ dataInicio.getFullYear() }} até {{ dataFim.getDate().toString().padStart(2, "0") +'/'+ (dataFim.getMonth() + 1).toString().padStart(2, "0") +'/'+ dataFim.getFullYear() }}
					</a>
            </div>
            <div class="w-max-content mb-2">
               <a href="javascript:;" class="badge badge-default bg-theme mb-1" @click="exportar"><i class="far fa-share font-10 me-1"></i> Exportar</a>
            </div>
         </div>

         <!-- Principal -->
			<div class="row">
            <!-- Cabecalho -->
            <div class="col-12 px-0 d-none d-lg-block" v-if="loja.vendas.length > 0">
               <div class="row row-cols-2 row-cols-lg-3 text-center font-13">
                  <div class="col mb-2">
                     <div class="card mb-0 h-100">
                        <div class="card-body py-2 px-0"><strong class="me-1 weight-500">{{ loja.tipoVisualizacao }}</strong></div>
                     </div>
                  </div>
                  <div class="col">
                     <div class="card mb-2">
                        <div class="card-body py-2 px-0"><i class="far fa-box me-2 font-12 color-theme"></i><strong class="me-1 weight-500">Quantidade</strong></div>
                     </div>
                  </div>
                  <div class="col">
                     <div class="card mb-2">
                        <div class="card-body py-2 px-0 weight-500"><i class="far fa-equals me-2 font-12 color-theme"></i><strong class="me-1 weight-500">Valor médio</strong></div>
                     </div>
                  </div>
               </div>
				</div>

            <ticket class="d-none d-lg-block" v-for="(ticket, index) in loja.vendas" :key="index" :index="index" :ticket="ticket" @buscarComprovantes="buscarComprovantes($event)" />
            
            <div class="col-12 my-5 text-center" v-if="loja.vendas.length == 0">
               <i class="fal fa-store-slash font-60 text-secondary opacity-50 d-block mb-3"></i>
               <h4 class="font-12 mt-4 mb-0 text-secondary text-uppercase">Nenhuma venda encontrada</h4>
            </div>

            <!-- Total -->
            <div class="col-12 px-0 mt-1" v-if="loja.vendas.length > 0">
               <div class="row row-cols-2 row-cols-lg-3 text-center font-13 weight-500">
                  <div class="col mb-2 d-none d-lg-block">
                     <div class="card mb-0 h-100">
                        <div class="card-body py-2 px-0"><strong class="me-1 weight-500">Total</strong></div>
                     </div>
                  </div>
                  <div class="col">
                     <div class="card mb-2">
                        <div class="card-body py-2 px-0">
                           <span class="d-block d-lg-none"><i class="far fa-box me-2 font-12 color-theme"></i><strong class="me-1 weight-500">Quantidade</strong></span>
                           {{ parseInt(loja.quantidadeTotal) }}
                        </div>
                     </div>
                  </div>
                  <div class="col">
                     <div class="card mb-2">
                        <div class="card-body py-2 px-0 color-theme">
                           <span class="d-block d-lg-none"><i class="far fa-equals me-2 font-12"></i><strong class="me-1 weight-500">Valor médio</strong></span>
                           <small class="font-10">R$</small> {{ parseFloat(loja.valorMedio).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits:2}) }}
                        </div>
                     </div>
                  </div>
               </div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

import ticket from '@/components/resultados/ticketMedio/Ticket.vue'

export default {
	name: 'Loja',
	props: ['loja', 'index'],
   data: function () {
      return {
			dataInicio: new Date(this.loja.dataInicio),
			dataFim: new Date(this.loja.dataFim)
      }
   },
   components: {
      ticket
   },
   methods: {
      exportar : function () {
         this.$store.dispatch('exportarExcel', {
				'nome': ('Ticket médio - '+ this.loja.nomeLoja),
				'resultado': Array.of(this.loja), 
				'campos': ['numeroLoja', 'nomeLoja', 'dataInicio', 'dataFim', 'quantidadeTotal', 'valorMedio'], 
				'lista': {'nome': 'vendas', 'campos': ['pdv', 'valor', 'quantidade']}
			})
      },
      buscarComprovantes : function (objPdv) {
         this.$emit('buscarComprovantes', {
            'nomeOperador': objPdv.nomeOperador,
            'numeroPdv': objPdv.numeroPdv,
            'operador': objPdv.operador,
            'pdv': objPdv.pdv,
            'idLoja': this.loja.idLoja,
            'numeroLoja': this.loja.numeroLoja,
            'dataInicio': this.loja.dataInicio,
            'dataFim': this.loja.dataFim,
            'formaPagamento': objPdv.formaPagamento
         })
      }
   }
}

</script>